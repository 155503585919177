import React, { useState } from "react"
import "_data/normalize.css"
import { ScreensLayout } from "_components/indexScreens/common/screensLayout"
import Content from "_components/indexScreens/_features/features_content"
import Seo from "_components/indexScreens/_features/features_seo"
import { CheckLocation } from "_components/indexScreens/common/CheckLocation"

const currentScreen = 1
const initialUpperContentMargin = 120 // was 35
const initialLowerContentMargin = 230 // 175
const screenBackgroundColor = "#f4f4f4"
const screenSwipeBackgroundColor = "white"

const Features = ({ location }) => {
  const [showFooter, setShowFooter] = useState(false)
  const [previousPageAllowed, setPreviousPageAllowed] = useState(true)
  const [nextPageAllowed, setNextPageAllowed] = useState(true)

  CheckLocation(
    location,
    setShowFooter,
    setNextPageAllowed,
    setPreviousPageAllowed
  )

  return (
    <>
      <Seo />
      {ScreensLayout(
        Content,
        screenSwipeBackgroundColor,
        screenBackgroundColor,
        initialUpperContentMargin,
        initialLowerContentMargin,
        showFooter,
        nextPageAllowed,
        previousPageAllowed,
        currentScreen
      )}
    </>
  )
}

export default Features
