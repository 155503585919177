import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const CardImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "cards" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      fluid={data.allFile.edges[props.id].node.childImageSharp.fluid}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    />
  )
}

export default CardImage
