import React, { useState } from "react"
import { useGesture } from "react-use-gesture"
import { useSprings, animated, interpolate } from "react-spring"
import styled from "styled-components"
import FeatureCardImage from "./features_content-cardImage"

const cards = ["", "", "", "", "", "", "", "", "", ""]
const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
})
const from = (i) => ({ x: -20, rot: 0, scale: 1.5, y: -1000 })
const trans = (r, s) =>
  `perspective(1500px) rotateX(20deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`

const Cards = (props) => {
  const [gone] = useState(() => new Set())
  const [cardProps, set] = useSprings(cards.length, (i) => ({
    ...to(i),
    from: from(i),
    config: { mass: 1, tension: 150, friction: 21 },
  }))

  const bind = useGesture({
    onDrag: ({
      args: [index],
      down,
      movement: [mx],
      distance,
      direction: [xDir],
      velocity,
    }) => {
      const trigger = velocity > 0
      const dir = xDir < 0 ? -1 : 1
      if (!down && trigger) gone.add(index)

      set((i) => {
        if (index !== i) return
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0)
        const scale = down ? 1.1 : 1

        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: {
            mass: 2,
            friction: 50,
            tension: down ? 400 : isGone ? 200 : 500,
          },
        }
      })
      if (!down && gone.size === cards.length)
        setTimeout(() => gone.clear() || set((i) => to(i)), 600)
    },
  })

  return cardProps.map(({ x, y, rot, scale }, i) => (
    <Wrapper
      key={i}
      style={{
        transform: interpolate(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
    >
      <Card
        {...bind(i)}
        style={{
          transform: interpolate([rot, scale], trans),
        }}
      >
        <FeatureCardImage id={i} />
      </Card>
    </Wrapper>
  ))
}

const Wrapper = styled(animated.div)`
  cursor: pointer;
  position: absolute;
  width: 270px;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    width: 250px;
    height: 250px;
  }
`

const Card = styled(animated.div)`
  position: absolute;
  background-color: orange;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
`

export default Cards
