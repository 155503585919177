import React from "react"
import {
  color_paragraph,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import PageHeadline from "_components/misc/pageHeadline"

const Copy = (props) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <motion.div
        initial={{ y: 30, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25, duration: 0.85, ease: "easeOut" }}
      >
        <motion.div style={{ x: props.titleX, y: props.titleY }}>
          <PageHeadline copy="The top 10 reasons our BLS tools will level up your EMDR practice." />
        </motion.div>
      </motion.div>
      <motion.div
        initial={{ y: 35, opacity: 0, rotate: 0.01 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.35, duration: 0.85, ease: "easeOut" }}
        style={{ marginLeft: ".5vw", color: "#303c6c" }}
      >
        <motion.div style={{ x: props.paraX, y: props.paraY }}>
          <Paragraph style={{ color: color_paragraph }}>
            Every EMDR therapist wants to provide their clients with the most
            cutting edge treatment. {" "}
            <span
              style={{
                fontWeight: "800",
                textDecoration: "none",
                color: color_paragraphHighlite,
              }}
            >
              {" "}
              ActiveEMDR
            </span>{" "}
            allows you to do what you do best using a reliable and client
            friendly platform.
          </Paragraph>
          <Paragraph style={{ color: color_paragraph }}>
            Swipe through these cards for just a few reasons why our tools will
            allow you to provide next-level BLS.
          </Paragraph>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Copy
