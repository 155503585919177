import React, { useEffect } from "react"
import { motion, useSpring, useTransform } from "framer-motion"
import { useMitt } from "react-mitt"
import Cards from "./features_content-cards"
import Copy from "./features_content-copy"
import styled from "styled-components"

const FeaturesContent = () => {
  const { emitter } = useMitt()
  let mouseX = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let mouseY = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let titleX = useTransform(mouseX, (value) => value / 400)
  let titleY = useTransform(mouseY, (value) => value / 400)
  let paraX = useTransform(mouseX, (value) => value / 560)
  let paraY = useTransform(mouseY, (value) => value / 580)

  useEffect(() => {
    emitter.on("mouseX", (event) => {
      const offsetX = event.data - window.innerWidth / 2
      mouseX.set(offsetX)
    })

    emitter.on("mouseY", (event) => {
      const offsetY = event.data - window.innerHeight / 2
      mouseY.set(offsetY)
    })
  }, [emitter, mouseX, mouseY])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Content>
        <CardsWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 2 }}
        >
          <Cards cardX={titleX} cardY={titleY} />
        </CardsWrapper>
        <CopyWrapper>
          <Copy titleX={titleX} titleY={titleY} paraX={paraX} paraY={paraY} />
        </CopyWrapper>
      </Content>
    </div>
  )
}

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  max-width: 1100px;
  @media only screen and (max-width: 2000px) {
    max-width: 1100px;
  }
  @media only screen and (max-width: 900px) {
    max-width: 650px;
    flex-direction: column;
  }
`

const CardsWrapper = styled(motion.div)`
  position: relative;
  z-index: 4;
  min-width: 340px;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    order: 2;
  }
  @media only screen and (max-width: 480px) {
    min-width: 90%;
    height: 400px;
  }
`

const CopyWrapper = styled.div`
  position: relative;
  z-index: 3;
  margin-left: 2vw;
  order: 0;
  @media only screen and (max-width: 900px) {
    order: 1;
  }
`

export default FeaturesContent
